import * as React from "react";

export interface MobileNavigationMenuProps {
  isMobileNavOpen?: boolean;
}

export const MobileNavigationMenu = ({
  isMobileNavOpen,
}: MobileNavigationMenuProps) => {
  return (
    <div className="mobile-nav-main">
      {/*<button className="mobile-nav-btn" onClick={handleToggleMobileNavDrawer}>*/}
      {/*  <div className="mobile-nav-btn-icon">*/}
      {/*    {isMobileNavDrawerOpen ? "✖" : "☰"}*/}
      {/*  </div>*/}
      {/*</button>*/}
      <div className={`drawer ${isMobileNavOpen ? "open" : ""}`}>
        <div className="mobile-nav-info-container">
          <div className="mobile-nav-info-card">
            <a href="/sales/guest/form">
              <div className="mobile-nav-track-order-icon"></div>
              <div>Track Order</div>
            </a>
          </div>
          <div className="mobile-nav-info-card">
            <a href="/support/home">
              <div className="mobile-nav-help-icon"></div>
              <div>Help Centre</div>
            </a>
          </div>
          <div className="mobile-nav-info-card">
            <a href="/locator">
              <div className="mobile-nav-locator-icon"></div>
              <div>Store Locator</div>
            </a>
          </div>
        </div>
        <div className="mobile-nav-gift-card-main">
          <a href="/gift-cards">
            <div className="mobile-nav-gift-card-text">
              SHOP OUR E-GIFT CARDS
            </div>
          </a>
        </div>
        <ul className="drawer-menu">
          <li className="drawer-item header">
            <a href="/clearance">
              CLEARANCE <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item header">
            <a href="/sale">
              SALE <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/bed">
              BED <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/bath">
              BATH <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/table">
              TABLE <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/home-decor">
              HOME DÉCOR <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/apparel">
              APPAREL <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/outdoor">
              OUTDOOR <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/kids">
              KIDS <span className="drawer-item-arrow"></span>
            </a>
          </li>
          <li className="drawer-item">
            <a href="/new">
              NEW <span className="drawer-item-arrow"></span>
            </a>
          </li>
        </ul>
        <div className="drawer-footer">
          <a href="/customer/account">
            <button className="drawer-footer-btn">
              <span className="mobile-nav-sign-icon"></span>SIGN IN OR JOIN
              REWARDS
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
