import * as React from "react";
import { MobileNavigationMenu } from "./mobile-navigation-menu";

interface AppProps {
  mountElement: Element;
}

export const App: React.FunctionComponent<AppProps> = (props) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  React.useEffect(() => {
    const mobileNavHamburgerBtnSelect = document.querySelector(
      ".hamburger.hamburger--squeeze, .hamburger.hamburger--squeeze.is-active",
    );
    const toggleNavMenu = (event: any) => {
      setIsMobileNavOpen(!isMobileNavOpen);
      event.preventDefault();
      event.stopPropagation();
    };
    mobileNavHamburgerBtnSelect?.addEventListener("click", toggleNavMenu);

    return () => {
      mobileNavHamburgerBtnSelect?.removeEventListener("click", toggleNavMenu);
    };
  }, [isMobileNavOpen]);

  return (
    <div>
      <MobileNavigationMenu isMobileNavOpen={isMobileNavOpen} />
    </div>
  );
};
