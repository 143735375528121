import "idempotent-babel-polyfill";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "./App";

const elementToMountReactAppOn = document.querySelector(
  "[data-react-mobile-navigation]",
);
if (elementToMountReactAppOn) {
  ReactDOM.render(
    <App mountElement={elementToMountReactAppOn} />,
    elementToMountReactAppOn,
  );
}
